<template>
  <div id="compbox">
    <div class="hadheight"></div>
    <Header />
    <!-- 申请退货 -->
    <div class="salesbox">
        <div class="basecont">
            <div class="cont">
                <div class="lt">
                    <div class="fz_title">
                        <div class="ft1">
                            <h1 class="f_tit basefont30">申请退货</h1>
                            <p>选择要退货的商品</p>
                        </div>
                        <router-link to="/my/myorder" @click="$router.back();" class="but">
                            <i><img src="../../assets/images/returniconig1.png" alt=""></i>
                            <span>返回订单列表</span>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="cont">
                <div class="lt">
                    <div class="shopcart_sign_cont">
                        <div class="item" v-for="(item,index) in salesListData" :key="index">
                            <div class="img">
                                <img :src="item.skuDefaultImg" :alt="item.skuName">
                            </div>
                            <div class="font">
                                <div class="tit">
                                    <div class="name">
                                        {{item.skuName}}
                                    </div>
                                </div>
                                <div class="text">{{item.skuDesc}}</div>
                                <div class="f_ne">
                                    <div class="fw_zen"></div>
                                    <a href="javascript:;"  class="jia">
                                        ¥ {{item.retailPrice}}
                                    </a>
                                </div>
                            </div>
                            <a href="javascript:;" 
                                @click="xuanzhong(index)" 
                                :class="{check:true,on:item.checked}">
                                <i></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="gt">
                    <div class="sale_gt">
                        <div class="f_jut">
                            <div class="f_tit basefont24">{{saGtClassData.title}}</div>
                            <template v-for="(item, index) in saGtClassData.children">
                                <label class="f_li" :key="index">
                                    <span>{{item.name}}</span>
                                    <i>
                                        <input type="checkbox" :name="'salesclass'+index" :value="item.value" v-model="saleClass">
                                        <span></span>
                                    </i>
                                </label>
                            </template>
                        </div>
                        <div class="f_jut">
                            <div class="f_tit basefont24">{{saGtCauseData.title}}</div>
                            <template v-for="(item, index) in saGtCauseData.children">
                                <label class="f_li" :key="index">
                                    <span>{{item.name}}</span>
                                    <i>
                                        <input type="checkbox" :name="'salesclass'+index" :value="item.value" v-model="salesCause">
                                        <span></span>
                                    </i>
                                </label>
                            </template>
                        </div>
                        <textarea class="f_textarea" v-model="textareaVal" placeholder="请输入其他原因" v-show="restsTo"></textarea>
                        <div class="f_file">
                            <div class="f_nei">
                                <i v-show="jud != 3"></i>
                                <span v-show="jud != 3">上传快递图片或者视频</span>
                                <div class="vi_tit" v-show="oddimg!='' && jud==3">
                                   <span>视频：</span><p>{{oddimg}}</p>
                                </div>
                            </div>
                            <img :src="oddimg" v-show="oddimg!='' && jud==2" alt="">
                            
                            <!-- <video :src="oddimg" v-show="oddimg!='' && jud==3" controls></video> -->
                            <input type="file" id="upload">
                        </div>
                        
                        <a href="javascript:;" @click="subSales()" class="hm-but f_but">
                            <span>提交申请</span>
                            <i>
                                <img src="../../assets/images/jiantouyo1.png" >
                                <img src="../../assets/images/jiantouyo2.png" >
                            </i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
const OSS = require('ali-oss');
export default {
  components: {
    Header,
    Footer
  },
  data(){
    return {
        saleClass: [],
        salesCause: [],
        textareaVal: "",
        salesListData: [],
        saGtClassData:{
            title: "选择退货类型",
            children:[
                {
                    name: "仅退款（未发货取消订单）",
                    value: "0"
                },
                {
                    name: "退货退款（针对已发货的订单）",
                    value: "1"
                }
            ]
        },
        saGtCauseData:{
            title: "选择退货原因",
            children:[
                {
                    name: "退运费",
                    value: "退运费"
                },
                {
                    name: "收到商品破损",
                    value: "收到商品破损"
                },
                {
                    name: "商品错发、漏发",
                    value: "商品错发、漏发"
                },
                {
                    name: "发票问题",
                    value: "发票问题"
                },
                {
                    name: "收到商品与描述不符",
                    value: "收到商品与描述不符"
                },
                {
                    name: "末按约定时间发货",
                    value: "末按约定时间发货"
                },
                {
                    name: "其他",
                    value: "其他"
                },
            ]
        },
        restsTo: false,
        myOrderParams: JSON.parse(localStorage.getItem("orderMessage")) || {},
        orderItemId: [],
        oddimg: "",
        jud: 1
    }
  },
  
  watch:{
    saleClass(lastName,firstName){
        if(lastName.length>1){
            this.saleClass = lastName.splice(lastName.length-1);
        }
    },
    salesCause(lastName,firstName){
        if(lastName.length>1){
            this.salesCause = lastName.splice(lastName.length-1);
        }
        var lent = this.saGtCauseData.children.length-1;
        if(lastName[0] == this.saGtCauseData.children[lent].value){
            this.restsTo = true;
        }else{
            this.restsTo = false;
        }
    },
    
  },
  created(){
      let orderList = this.myOrderParams.orderItemInfoList.map(v => {
        v.checked = false;
        return v;
      });
      this.salesListData = JSON.parse(JSON.stringify(orderList));
      console.log(this.myOrderParams);
  },
  mounted(){
    /* 图片上传 */
    this.uploadFile();
  },
  methods: {
    xuanzhong(_in){
        this.salesListData[_in].checked = !this.salesListData[_in].checked;
        this.orderItemId = [];
        this.salesListData.forEach(v => {
            if(v.checked){
                this.orderItemId.push(v.id);
            }
        });
    },
    subSales(){
        var dataJudge = this.salesListData.find(v => v.checked == true); //判断是否选中
        if(!dataJudge){
            this.$message.error('请选择要退货的商品！');
            return false;
        }
        if(this.saleClass.length==0){
            this.$message.error('请选择退货类型！');
            return false;
        }
        if(this.salesCause.length==0){
            this.$message.error('请选择退货原因！');
            return false;
        }
        if(this.oddimg==""){
            this.$message.error('请上传产品图片！');
            return false;
        }
        let data = {
            orderId: this.myOrderParams.orderNo,
            refundCause: this.restsTo?this.textareaVal:this.salesCause[0],
            refundType: this.saleClass[0],
            orderItemId: this.orderItemId,
            imgUrl: this.oddimg
        }
        console.log(data);
        this.$axios.post('/api2/portal/auth/order/refund/insert',data).then(res => {
            console.log(res);
            if(res.code == "000000"){
                this.$message({
                    message: "提交成功",
                    type: "success"
                });
                this.$router.push("/my/myorder");
            }else{
                this.$message({
                    message: res.message,
                    type: "error"
                });
            }
        }).catch(error => {
            console.log(error);
        });
    },
    uploadFile(){
        /* 获取上传OSStoken */
        const _this =this;
        let client;
        _this.$axios.post('/api2/portal/storage/file/fileInfo/getToken').then(res=>{
            this.uploadToken = res.data;
             client = new OSS({
                // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
                region: this.uploadToken.region,
                // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
                accessKeyId: this.uploadToken.accessKeyId,
                accessKeySecret: this.uploadToken.accessKeySecret,
                // 从STS服务获取的安全令牌（SecurityToken）。
                stsToken: this.uploadToken.securityToken,
                // 填写Bucket名称。
                bucket: this.uploadToken.bucketName
            });
        }).catch(error=>{
            console.log(error);
        });
        

      // 从输入框获取file对象，例如<input type="file" id="file" />。
      let data;
      // 创建并填写Blob数据。
      //const data = new Blob(['Hello OSS']);
      // 创建并填写OSS Buffer内容。
      //const data = new OSS.Buffer(['Hello OSS']);

      const upload = document.getElementById("upload");

       async function putObject (data,str1) {
         try {
           // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
           // 您可以通过自定义文件名（例如exampleobject.txt）或文件完整路径（例如exampledir/exampleobject.txt）的形式实现将数据上传到当前Bucket或Bucket中的指定目录。
           // data对象可以自定义为file对象、Blob数据或者OSS Buffer。
          const result = await client.put(
            str1,
            data
            //{headers}
          );
          _this.oddimg=result.url;
          console.log(_this.oddimg);
        } catch (e) {
          console.log(e);
        }
      }

      upload.addEventListener("change", () => {
        data = document.getElementById("upload").files[0];
        var imgSize = data.size;  //b
        if(imgSize>1024*1024*10){//10M
            return this.$message.error('上传图片或者视频不能超过1M');
        }
        console.log(data.type);
        if( data.type != 'image/png' &&
            data.type != 'image/jpeg' &&
            data.type != 'image/gif' &&
            data.type != 'video/mp4' &&
            data.type != 'video/wmv' &&
            data.type != 'video/mkv' &&
            data.type != 'video/mdv-avi'){
            return this.$message.error('图片或者视频的格式上传格式不正确');
        }else{
            if( data.type == 'image/png' ||
                data.type == 'image/jpeg' ||
                data.type == 'image/gif'){
                this.jud = 2
            }else{
                this.jud = 3
            }
        }
        console.log(this.jud);
        var in1 = data.name.lastIndexOf(".");
        var str1 = data.name.substring(in1);
        var date = "gzc" + new Date().getTime() + str1;
        console.log(date,date);
        putObject(data,date);
      });
    }
  }
}
</script>
